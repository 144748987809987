<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb
      :title="page.title"
      :icon="'mdi-settings-outline'"
    ></BaseBreadcrumb>

    <v-card class="mt-4">
      <v-card-title class="text-h6">API </v-card-title>
      <v-card-text>
        <p class="text-body-2">
          La integración con nuestra API sirve para utilizar la funcionalidad de
          nuestra firma digital desde sus aplicaciones. Puede ver la información
          completa sobre las llamadas en la página de soporte:
          <a @click="openFirmarOnlineSupport">
            https://soporte.firmar.online
          </a>
        </p>

        <span class="text-body-1">Clave de Api: </span>
        <v-chip class="text-body-1 ml-1">
          {{ userProfileApiKey }}
        </v-chip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" @click="copyApiKey()">
              <v-icon>
                mdi-content-copy
              </v-icon>
            </v-btn>
          </template>
          <span>Copiar Clave de Api</span>
        </v-tooltip>

        <v-input v-show="false"> {{ userProfileApiKey }} </v-input>

        <div class="text-body-2 my-3">
          Si considera que su clave de API ha sido comprometida, puede generar
          una nueva. Esta acción invalidará la clave actual.
        </div>

        <v-btn
          color="primary"
          @click="generateNewApiKeyDialog = true"
          :disabled="!havePlanWriteMode"
          class="btn--expanded"
        >
          Generar nueva clave
        </v-btn>
      </v-card-text>
    </v-card>

    <v-card class="mt-4" v-if="isUserAdmin">
      <v-card-title class="text-h6">Webhook </v-card-title>
      <v-card-text>
        <p class="text-body-2">
          Aquí puede configurar una URL para la notificación de eventos de
          procesamiento de documentos.
        </p>
        <span class="text-body-1">Webhook actual: </span>
        <v-chip class="text-body-1 mt-1">
          {{ userProfileWebhook }}
        </v-chip>

        <div
          class="d-flex align-center mt-5 "
          v-if="userProfileWebhookHeader !== null"
        >
          <span class="text-body-1">Cabecera actual: </span>
          <v-chip class="text-body-1 ml-1">
            {{ userProfileWebhookHeader }} : {{ userProfileWebhookValue }}
          </v-chip>
        </div>
        <v-tooltip bottom v-if="userProfileWebhook !== null">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              @click="testWebook()"
              class="mt-4 btn--expanded d-flex"
              color="primary"
            >
              <v-icon class="mr-1">mdi-play-circle-outline</v-icon>
              Test
            </v-btn>
          </template>
          <span>Test webhook</span>
        </v-tooltip>
        <v-text-field
          class="mt-5"
          dense
          v-model="newWebhookURL"
          hint="https://..."
          label="Webhook URL"
          outlined
          :disabled="!havePlanWriteMode"
        ></v-text-field>

        <v-text-field
          dense
          v-model="newWebhookHeader"
          hint="Authorization..."
          label="Nombre cabecera http"
          outlined
          :disabled="!havePlanWriteMode"
        ></v-text-field>

        <v-text-field
          dense
          v-model="newWebhookHeaderValue"
          hint="Token UFJxfaqb..."
          label="Valor cabecera http"
          outlined
          :disabled="!havePlanWriteMode"
        ></v-text-field>
        <v-btn
          color="primary"
          @click="updateWebhookURL"
          :disabled="!havePlanWriteMode || webhookHeaderNotComplete"
          class="mt-2 btn--expanded"
        >
          Guardar webhook
        </v-btn>
      </v-card-text>
    </v-card>

    <v-card class="mt-4" v-if="isUserAdmin">
      <v-card-title class="text-h6">Sobres finalizados</v-card-title>
      <v-card-text>
        <p class="text-body-2">
          Aquí puede configurar una URL para recibir una copia de los sobres
          finalizados.
          <br />
          Para funcionar, debe tener un recurso configurado en esta URL que
          admita un método <strong>POST</strong> y el reciba el contenido del
          PDF en <strong>un array del bytes</strong> en el Body. El content-type
          debe ser <strong>application/pdf</strong>
          <br />
          Si un sobre dispone de varios documentos, se recibirán uno a uno.
        </p>

        <span class="text-body-1">URL de POST actual </span>
        <v-chip class="text-body-1 ml-1">
          {{ userProfileSendDocumentSetPostURL }}
        </v-chip>

        <v-text-field
          class="mt-4"
          dense
          v-model="sendDocumentSetPost"
          hint="https://..."
          label="POST URL"
          outlined
          :disabled="!havePlanWriteMode"
        ></v-text-field>
        <v-btn
          color="primary"
          @click="updateSendDocumentSetPost"
          :disabled="!havePlanWriteMode"
          class="btn--expanded"
        >
          Guardar nueva URL de POST
        </v-btn>
      </v-card-text>
    </v-card>

    <v-card class="mt-4 mb-4">
      <v-card-title class="text-h6">Complementos</v-card-title>
      <v-card-text>
        <p class="text-body-2">
          Los complementos sirven para enviar documentos desde Google Drive,
          Office, etc para utilizar la funcionalidad de nuestra firma digital.
        </p>

        <p class="text-body-2">Solicitud predeterminada:</p>
        <v-spacer></v-spacer>
        <v-select
          class="col-4"
          :items="[
            { value: 0, text: 'Preguntar para cada documento' },
            { value: 1, text: 'Siempre firma remota' },
            { value: 2, text: 'Siempre firma presencial' }
          ]"
          @change="onDefaultSign"
          v-model="defaultSignRequestType"
          outlined
          dense
          required
        ></v-select>

        <v-btn
          :loading="onDefaultSignLoading"
          color="primary"
          @click="editComplementSettings()"
          class="btn--expanded"
        >
          Actualizar
        </v-btn>
      </v-card-text>
    </v-card>

    <v-dialog scrollable v-model="generateNewApiKeyDialog" max-width="350">
      <v-card>
        <v-card-title class="headline justify-center mb-4">
          <v-icon size="70" color="warning">mdi-alert-outline</v-icon>
        </v-card-title>
        <v-card-subtitle class="font-weight-bold headline  text-center">
          Confirmar acción
        </v-card-subtitle>
        <v-card-text class="text-center"
          >¿Está seguro de generar una nueva clave de API? Esta acción
          invalidará su clave de API actual</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="generateNewApiKeyDialog = false"
            >Volver</v-btn
          >
          <v-btn
            text
            :loading="isGeneratingNewApiKey"
            class="ml-5"
            color="primary"
            @click="generateNewApiKey()"
          >
            Generar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      scrollable
      persistent
      :no-click-animation="true"
      v-model="editCompanyLogo"
      max-width="800"
    >
      <v-card :loading="editCompanyLogoProgress">
        <v-card-title class="text-h5">
          <span class="headline">Editar Imagen Cabecera</span>
        </v-card-title>
        <v-card-text>
          <v-divider></v-divider>
          <v-file-input
            :disabled="editCompanyLogoProgress"
            ref="refFileInputCustomLogo"
            @change="onChangeImg"
            v-model="customLogo"
            class="fileInputCustomLogo"
            :rules="rules"
            accept="image/jpeg, image/jpg"
            placeholder="Selecciona una imagen de cabecera"
            prepend-icon="mdi-camera"
          ></v-file-input>
          <v-img
            ref="refImgCustomLogo"
            :src="urlCustomLogo"
            :aspect-ratio="7"
            contain
            class="lightgrey imgCustomLogo"
          ></v-img>
          <v-divider></v-divider>
          <div class="row">
            <div class="col flex-end">
              <v-btn
                color="primary"
                class="mt-2 mr-3"
                @click="editCompanyLogo = false"
                text
              >
                Cerrar</v-btn
              >
              <v-btn
                color="primary"
                class="mt-2 mr-3"
                :loading="applyCompanyLogoLoading"
                @click="applyCompanyLogo()"
                :disabled="applyCompanyLogoButtonDisabled"
              >
                Aplicar</v-btn
              >
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      scrollable
      persistent
      :no-click-animation="true"
      v-model="editMailTemplates"
      max-width="1200"
      @input="v => v || onResetMailTemplate()"
    >
      <v-card :loading="editMailTemplatesProgress">
        <v-card-title class="text-h5">
          <span class="headline">Editar Textos</span>
        </v-card-title>
        <v-card-text>
          <v-divider></v-divider>
          <div class="bodyMailTemplate">
            <div class="row">
              <div class="col-md-4 col-sm col-xs">
                <v-card-title class="text-h6"
                  >Firma
                  <v-tooltip bottom
                    ><template v-slot:activator="{ on, attrs }"
                      ><v-icon v-on="on" v-bind="attrs" class="ml-3"
                        >mdi-information-outline</v-icon
                      ></template
                    ><span
                      >Plantilla para la solicitud de un proceso de firma</span
                    >
                  </v-tooltip></v-card-title
                >
                <v-text-field
                  :disabled="editMailTemplatesProgress"
                  dense
                  outlined
                  label="Asunto"
                  class="ml-3 mr-3"
                  placeholder="Introduzca un asunto"
                  v-model="mailTemplateData.signEmailSubject"
                ></v-text-field>
                <v-textarea
                  :disabled="editMailTemplatesProgress"
                  :no-resize="true"
                  outlined
                  label="Descripción"
                  class="ml-3 mr-3"
                  placeholder="Introduzca la descripción del email"
                  v-model="mailTemplateData.signEmailBody"
                ></v-textarea>
              </div>
              <div class="col-md-4 col-sm col-xs">
                <v-card-title class="text-h6"
                  >Completado<v-tooltip bottom
                    ><template v-slot:activator="{ on, attrs }"
                      ><v-icon v-on="on" v-bind="attrs" class="ml-3"
                        >mdi-information-outline</v-icon
                      ></template
                    ><span
                      >Plantilla para la finalización de un proceso de
                      firma</span
                    >
                  </v-tooltip></v-card-title
                >
                <v-text-field
                  :disabled="editMailTemplatesProgress"
                  dense
                  outlined
                  label="Asunto"
                  class="ml-3 mr-3"
                  placeholder="Introduzca un asunto"
                  v-model="mailTemplateData.completedEmailSubject"
                ></v-text-field>
                <v-textarea
                  :disabled="editMailTemplatesProgress"
                  :no-resize="true"
                  outlined
                  label="Descripción"
                  class="ml-3 mr-3"
                  placeholder="Introduzca la descripción del email"
                  v-model="mailTemplateData.completedEmailBody"
                ></v-textarea>
              </div>
              <div class="col-md-4 col-sm col-xs">
                <v-card-title class="text-h6"
                  >Rechazado<v-tooltip bottom
                    ><template v-slot:activator="{ on, attrs }"
                      ><v-icon v-on="on" v-bind="attrs" class="ml-3"
                        >mdi-information-outline</v-icon
                      ></template
                    ><span
                      >Plantilla para el rechazo de un proceso de firma</span
                    >
                  </v-tooltip></v-card-title
                >
                <v-text-field
                  :disabled="editMailTemplatesProgress"
                  dense
                  outlined
                  label="Asunto"
                  class="ml-3 mr-3"
                  placeholder="Introduzca un asunto"
                  v-model="mailTemplateData.rejectedEmailSubject"
                ></v-text-field>
                <v-textarea
                  :disabled="editMailTemplatesProgress"
                  :no-resize="true"
                  outlined
                  label="Descripción"
                  class="ml-3 mr-3"
                  placeholder="Introduzca la descripción del email"
                  v-model="mailTemplateData.rejectedEmailBody"
                ></v-textarea>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 col-sm col-xs">
                <v-card-title class="text-h6"
                  >Recordatorio<v-tooltip bottom
                    ><template v-slot:activator="{ on, attrs }"
                      ><v-icon v-on="on" v-bind="attrs" class="ml-3"
                        >mdi-information-outline</v-icon
                      ></template
                    ><span
                      >Plantilla para el recordatorio de un proceso de
                      firma</span
                    >
                  </v-tooltip></v-card-title
                >
                <v-text-field
                  :disabled="editMailTemplatesProgress"
                  dense
                  outlined
                  label="Asunto"
                  class="ml-3 mr-3"
                  placeholder="Introduzca un asunto"
                  v-model="mailTemplateData.rememberEmailSubject"
                ></v-text-field>
                <v-textarea
                  :disabled="editMailTemplatesProgress"
                  :no-resize="true"
                  outlined
                  label="Descripción"
                  class="ml-3 mr-3"
                  placeholder="Introduzca la descripción del email"
                  v-model="mailTemplateData.rememberEmailBody"
                ></v-textarea>
              </div>
              <div class="col-md-4 col-sm col-xs">
                <v-card-title class="text-h6"
                  >Notificación<v-tooltip bottom
                    ><template v-slot:activator="{ on, attrs }"
                      ><v-icon v-on="on" v-bind="attrs" class="ml-3"
                        >mdi-information-outline</v-icon
                      ></template
                    ><span
                      >Plantilla para la notificación de un proceso de
                      firma</span
                    >
                  </v-tooltip></v-card-title
                >
                <v-text-field
                  :disabled="editMailTemplatesProgress"
                  dense
                  outlined
                  label="Asunto"
                  class="ml-3 mr-3"
                  placeholder="Introduzca un asunto"
                  v-model="mailTemplateData.notificationEmailSubject"
                ></v-text-field>
                <v-textarea
                  :disabled="editMailTemplatesProgress"
                  :no-resize="true"
                  outlined
                  label="Descripción"
                  class="ml-3 mr-3"
                  placeholder="Introduzca la descripción del email"
                  v-model="mailTemplateData.notificationEmailBody"
                ></v-textarea>
              </div>
              <div class="col-md-4 col-sm col-xs">
                <v-card-title class="text-h6"
                  >Cancelado<v-tooltip bottom
                    ><template v-slot:activator="{ on, attrs }"
                      ><v-icon v-on="on" v-bind="attrs" class="ml-3"
                        >mdi-information-outline</v-icon
                      ></template
                    ><span
                      >Plantilla para la cancelación de un proceso de
                      firma</span
                    >
                  </v-tooltip></v-card-title
                >
                <v-text-field
                  :disabled="editMailTemplatesProgress"
                  dense
                  outlined
                  label="Asunto"
                  class="ml-3 mr-3"
                  placeholder="Introduzca un asunto"
                  v-model="mailTemplateData.canceledEmailSubject"
                ></v-text-field>
                <v-textarea
                  :disabled="editMailTemplatesProgress"
                  :no-resize="true"
                  outlined
                  label="Descripción"
                  class="ml-3 mr-3"
                  placeholder="Introduzca la descripción del email"
                  v-model="mailTemplateData.canceledEmailBody"
                ></v-textarea>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 col-sm col-xs">
                <v-card-title class="text-h6"
                  >Expirado<v-tooltip bottom
                    ><template v-slot:activator="{ on, attrs }"
                      ><v-icon v-on="on" v-bind="attrs" class="ml-3"
                        >mdi-information-outline</v-icon
                      ></template
                    ><span
                      >Plantilla para la expiración de un proceso de firma</span
                    >
                  </v-tooltip></v-card-title
                >
                <v-text-field
                  :disabled="editMailTemplatesProgress"
                  dense
                  outlined
                  label="Asunto"
                  class="ml-3 mr-3"
                  placeholder="Introduzca un asunto"
                  v-model="mailTemplateData.expiredEmailSubject"
                ></v-text-field>
                <v-textarea
                  :disabled="editMailTemplatesProgress"
                  :no-resize="true"
                  outlined
                  label="Descripción"
                  class="ml-3 mr-3"
                  placeholder="Introduzca la descripción del email"
                  v-model="mailTemplateData.expiredEmailBody"
                ></v-textarea>
              </div>
            </div>
          </div>
          <v-divider></v-divider>
          <div class="row">
            <div class="col flex-start">
              <v-menu
                top
                right
                :offset-y="true"
                origin="center center"
                transition="scale-transition"
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    class="mt-2"
                    v-bind="attrs"
                    v-on="on"
                    text
                  >
                    Ver Variables
                  </v-btn>
                </template>
                <v-list class="flex-column-start listVariables">
                  <div v-for="(item, index) in listVariables" :key="index">
                    <v-chip class="text-body-5">{{
                      "{" + item.code + "}"
                    }}</v-chip
                    ><v-btn
                      class="ml-2 mr-2"
                      icon
                      color="gray"
                      @click="copyText('{' + item.code + '}')"
                      ><v-icon>mdi-content-copy</v-icon></v-btn
                    ><span class="text-body-5">{{ "* " + item.name }}</span>
                  </div>
                </v-list>
              </v-menu>
            </div>
            <div class="col flex-end">
              <v-btn
                color="primary"
                class="mt-2 mr-3"
                @click="editMailTemplates = false"
                text
              >
                Cerrar</v-btn
              >
              <v-btn
                color="primary"
                class="mt-2 mr-3"
                :disabled="applyMailTemplatesButtonDisabled"
                :loading="applyMailTemplatesLoading"
                @click="applyMailTemplates()"
              >
                Aplicar</v-btn
              >
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      scrollable
      v-model="isShowingWebHookTestDialog"
      persistent
      max-width="1200"
    >
      <v-card>
        <v-card-title class="text-h5"> WEBHOOK TEST </v-card-title>
        <v-card-text>
          <pre class="text-body-1 text-left">
          {{ webHookTestDataReceived }}
         </pre
          >
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="onCloseWebHookTest">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {
  FileToDataUrl,
  DataURLtoFile,
  AssignObj,
  EqualToNull,
  ScaleDataURL
} from "../../utils/utils";
import { DefaultMailTemplateData } from "../../utils/constants";
import { mdiCog } from "@mdi/js";

export default {
  name: "Settings",

  data: () => ({
    onDefaultSignLoading: false,
    defaultSignRequestType: 0,
    mdiCogOutline: mdiCog,
    messageSuccessChangeCompanyLogo:
      "La imagen de cabecera se ha actualizado correctamente",
    messageErrorChangeCompanyLogo:
      "No se ha podido actualizar la imagen de cabecera",
    messageSuccessChangeMailTemplates:
      "Las plantillas de email se han actualizado correctamente",
    messageErrorChangeMailTemplates:
      "No se han podido actualizar las plantillas de email",
    newWebhookURL: "",
    newWebhookHeader: "",
    newWebhookHeaderValue: "",
    sendDocumentSetPost: "",
    isGeneratingNewApiKey: false,
    generateNewApiKeyDialog: false,
    applyCompanyLogoButtonDisabled: true,
    applyCompanyLogoLoading: false,
    editCompanyLogo: false,
    editCompanyLogoProgress: true,
    applyMailTemplatesButtonDisabled: true,
    editMailTemplates: false,
    editMailTemplatesProgress: true,
    applyMailTemplatesLoading: false,
    getProfileError: false,
    loadingApiSettings: false,
    customLogo: null,
    urlCustomLogo: null,
    listVariables: [
      { name: "Nombre asociado al sobre", code: "DocumentSetName" },
      { name: "Nombre del emisor", code: "SenderName" },
      { name: "Email del emisor", code: "SenderMail" },
      { name: "Nombre del destinatario", code: "RecipientName" },
      { name: "Email del destinatario", code: "RecipientEmail" },
      { name: "Teléfono del destinatario", code: "RecipientPhoneNumber" },
      { name: "Fecha de creación del sobre", code: "CreationDate" },
      { name: "Fecha de expiración del sobre", code: "ExpirationDate" },
      { name: "Fecha actual", code: "CurrentDate" }
    ],
    mailTemplateData: DefaultMailTemplateData(),
    rules: [
      value =>
        !value ||
        ((value.size < 500000 ||
          "La imagen de cabecera debe de ser menos a 0.5 MB!") &&
          (["image/jpeg", "image/jpg"].includes(value && value.type) ||
            "Solo se acepa una imagen de tipo .jpg"))
    ],
    page: {
      title: "Integración API"
    },
    isShowingWebHookTestDialog: false,
    webHookTestDataReceived: "",
    testWebhookRandomId: 0
  }),

  created() {
    this.getUserApiSettings();
  },

  mounted() {
    this.applyMailTemplatesButtonDisabled = true;
    this.getComplementSettings();
  },

  beforeDestroy() {
    this.onCloseWebHookTest();
  },

  computed: {
    isUserAdmin() {
      return this.$store.getters["user/isLoggedUserAdmin"];
    },
    userProfileApiKey() {
      return this.$store.getters["user/userProfileApiKey"];
    },
    userProfileWebhook() {
      return this.$store.getters["user/userProfileWebhook"];
    },
    userProfileWebhookHeader() {
      return this.$store.getters["user/userProfileWebhookHeader"];
    },
    userProfileWebhookValue() {
      return this.$store.getters["user/userProfileWebhookValue"];
    },
    userProfileSendDocumentSetPostURL() {
      return this.$store.getters["user/userProfileSendDocumentSetPost"];
    },
    havePlanWriteMode() {
      return this.$store.getters.havePlanWriteMode;
    },
    webhookHeaderNotComplete() {
      const isHeaderNotEmpty = this.newWebhookHeader.length > 0;
      const isURLNotEmpty = this.newWebhookURL.length > 0;
      const isHeaderValueNotEmpty = this.newWebhookHeaderValue.length > 0;

      return (
        (isHeaderNotEmpty && (!isURLNotEmpty || !isHeaderValueNotEmpty)) ||
        (!isHeaderNotEmpty && isURLNotEmpty && isHeaderValueNotEmpty) ||
        (!isURLNotEmpty && isHeaderValueNotEmpty)
      );
    }
  },
  watch: {
    editCompanyLogo: function(newEditCompanyLogo) {
      if (!newEditCompanyLogo) {
        this.urlCustomLogo = null;
        this.customLogo = null;
      }
    },
    mailTemplateData: {
      handler: function() {
        this.applyMailTemplatesButtonDisabled = false;
      },
      deep: true
    }
  },
  methods: {
    onDefaultSign(val) {
      this.defaultSign = val;
    },
    onResetMailTemplate() {
      this.mailTemplateData = DefaultMailTemplateData();
    },
    applyMailTemplates() {
      this.applyMailTemplatesLoading = true;
      this.$store
        .dispatch(
          "updateMailtemplates",
          EqualToNull(DefaultMailTemplateData(), this.mailTemplateData)
        )
        .then(() => {
          AssignObj(DefaultMailTemplateData(), this.mailTemplateData);
          this.$toasted.global.toast_success({
            message: this.messageSuccessChangeMailTemplates
          });
        })
        .catch(() => {
          this.$toasted.global.toast_error({
            message: this.messageErrorChangeMailTemplates
          });
        })
        .finally(() => {
          this.applyMailTemplatesButtonDisabled = true;
          this.applyMailTemplatesLoading = false;
        });
    },
    applyCompanyLogo() {
      this.applyCompanyLogoLoading = true;
      if (this.customLogo) this.saveCompanyLogo();
      else this.deleteCompanyLogo();
    },
    saveCompanyLogo() {
      FileToDataUrl(this.customLogo)
        .then(data => {
          if (data && data.split && data.split(",")[1]) {
            ScaleDataURL("data:image/jpg;base64," + data.split(",")[1], 7).then(
              base64Info => {
                if (
                  base64Info &&
                  base64Info.split &&
                  base64Info.split(",")[1]
                ) {
                  this.$store
                    .dispatch(
                      "user/updateCompanyLogo",
                      base64Info.split(",")[1]
                    )
                    .then(() => {
                      this.applyCompanyLogoButtonDisabled = true;
                      this.$toasted.global.toast_success({
                        message: this.messageSuccessChangeCompanyLogo
                      });
                    })
                    .catch(() => {
                      this.$toasted.global.toast_error({
                        message: this.messageErrorChangeCompanyLogo
                      });
                    })
                    .finally(() => {
                      this.applyCompanyLogoLoading = false;
                    });
                }
              }
            );
          }
        })
        .catch(() => {
          this.applyCompanyLogoLoading = false;
          this.$toasted.global.toast_error({
            message: this.messageErrorChangeCompanyLogo
          });
        });
    },
    deleteCompanyLogo() {
      this.$store
        .dispatch("user/deleteCompanyLogo")
        .then(() => {
          this.applyCompanyLogoButtonDisabled = true;
          this.$toasted.global.toast_success({
            message: this.messageSuccessChangeCompanyLogo
          });
        })
        .catch(() => {
          this.$toasted.global.toast_error({
            message: this.messageErrorChangeCompanyLogo
          });
        })
        .finally(() => {
          this.applyCompanyLogoLoading = false;
        });
    },
    onEditCompanyLogo() {
      this.applyCompanyLogoButtonDisabled = true;
      this.editCompanyLogoProgress = true;
      this.$store
        .dispatch("user/getCompanyLogo")
        .then(data => {
          if (data) {
            ScaleDataURL("data:image/jpg;base64," + data, 7).then(
              customData => {
                this.customLogo = DataURLtoFile(customData, "");
                this.previewImage();
              }
            );
          }
          this.editCompanyLogoProgress = false;
        })
        .catch(err => {
          console.log(err);
          this.editCompanyLogoProgress = false;
        });
      this.editCompanyLogo = true;
    },
    onChangeImg() {
      if (
        !this.customLogo ||
        (this.customLogo.size < 500000 &&
          ["image/jpeg", "image/jpg"].includes(
            this.customLogo && this.customLogo.type
          ))
      ) {
        this.applyCompanyLogoButtonDisabled = false;
        this.previewImage();
      } else this.applyCompanyLogoButtonDisabled = true;
    },
    previewImage() {
      this.urlCustomLogo =
        this.customLogo && URL.createObjectURL(this.customLogo);
    },
    onEditMailTemplates() {
      this.applyMailTemplatesButtonDisabled = true;
      this.editMailTemplatesProgress = true;
      this.$store
        .dispatch("getMailtemplates")
        .then(data => {
          this.mailTemplateData = AssignObj(DefaultMailTemplateData(), data);
          this.editMailTemplatesProgress = false;
        })
        .catch(err => {
          console.log(err);
          this.editMailTemplatesProgress = false;
        })
        .finally(() => {
          this.applyMailTemplatesButtonDisabled = true;
        });
      this.editMailTemplates = true;
    },
    copyText(text) {
      navigator.clipboard.writeText(text);
    },
    copyApiKey() {
      navigator.clipboard.writeText(this.userProfileApiKey);
      this.$toasted.global.toast_success({
        message: "Se ha copiado la clave de API al portapapeles"
      });
    },

    async testWebook() {
      this.isShowingWebHookTestDialog = true;
      this.webHookTestDataReceived += " \nEsperando respuesta...\n";
      this.testWebhookRandomId = Math.floor(Math.random() * 99999999);
      try {
        const resp = await this.$store.dispatch(
          "testWebhook",
          this.testWebhookRandomId
        );

        if (resp) {
          this.webHookTestDataReceived = "\nResultado: OK";
        } else {
          this.webHookTestDataReceived = "\nResultado: Error";
        }
      } catch (err) {
        this.webHookTestDataReceived = "\n" + err.response.data;
      }
    },

    onCloseWebHookTest() {
      this.isShowingWebHookTestDialog = false;
      this.webHookTestDataReceived = "";
    },

    updateWebhookURL() {
      const webhook = {
        webhook: this.newWebhookURL,
        webhookHeaderName: this.newWebhookHeader,
        webhookHeaderValue: this.newWebhookHeaderValue
      };

      this.$store
        .dispatch("user/updateWebhook", webhook)
        .then(() => {})
        .catch(err => {
          console.log(err);
          this.$toasted.global.toast_error({
            message: "No se ha podido actualizar el webhook"
          });
        });
    },

    updateSendDocumentSetPost() {
      this.$store
        .dispatch("user/sendDocumentSetPost", this.sendDocumentSetPost)
        .then(() => {})
        .catch(err => {
          console.log(err);
          this.$toasted.global.toast_error({
            message: "No se ha podido actualizar la url del sobre finalizado"
          });
        });
    },

    generateNewApiKey() {
      this.isGeneratingNewApiKey = true;
      this.$store
        .dispatch("user/generateNewApiKey")
        .then(() => {
          this.isGeneratingNewApiKey = false;
          this.$toasted.global.toast_success({
            message: "Se ha generado una nueva clave de API"
          });
          this.generateNewApiKeyDialog = false;
        })
        .catch(err => {
          console.log(err);
          this.isGeneratingNewApiKey = false;
          this.$toasted.global.toast_error({
            message: "No se ha podido generar una nueva clave de API"
          });
          this.generateNewApiKeyDialog = false;
        });
    },

    getUserApiSettings() {
      this.loadingApiSettings = true;
      this.$store
        .dispatch("user/getUserApiSettings")
        .then(() => {
          this.loadingApiSettings = false;
        })
        .catch(err => {
          console.log(err);
          this.loadingApiSettings = false;
          this.$toasted.global.toast_error({
            message: "No se han podido actualizar los datos"
          });
        });
    },

    getComplementSettings() {
      this.onDefaultSignLoading = true;
      this.$store
        .dispatch("getPrinterConfiguration")
        .then(() => {
          if (this.$store.state.printerConfiguration.requestType) {
            this.defaultSignRequestType = this.$store.state.printerConfiguration.requestType;
          }
        })
        .catch(err => {
          console.log(err);
          this.$toasted.global.toast_error({
            message:
              "No se ha podido obtener la configuración de los complementos"
          });
        })
        .finally(() => {
          this.onDefaultSignLoading = false;
        });
    },

    editComplementSettings() {
      this.onDefaultSignLoading = true;
      this.$store
        .dispatch("setPrinterConfiguration", {
          requestType: this.defaultSign,
          useFlow: false
        })
        .then(() => {
          this.$toasted.global.toast_success({
            message: "Se ha guardado correctamente la configuración"
          });
        })
        .catch(err => {
          console.log(err);
          this.$toasted.global.toast_error({
            message: "No se ha podido guardar la configuración"
          });
        })
        .finally(() => {
          this.onDefaultSignLoading = false;
        });
    },

    openFirmarOnlineSupport() {
      window.open("https://soporte.firmar.online", "_blank");
    }
  }
};
</script>
<style lang="scss" scoped>
#canvas-container {
  width: 100%;
  text-align: center;
}
canvas {
  display: inline;
}
@media (max-width: 560px) {
  .btn--expanded {
    width: 100%;
  }
}
</style>
